let apiEndpoint, shop, host;
let config = {};
let backStockCurrentScriptUrl = document.currentScript.src;
let backStockDomainName = backStockCurrentScriptUrl.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
shop = params?.shop ? params?.shop : '';
host = params?.host;

if (backStockDomainName === "localhost:3000") {
    apiEndpoint = "http://localhost:4000/node/admin_api";
    shop = "hiral-backinstock.myshopify.com";
    host = 'YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvaGlyYWxhdmFpeWE';
    config = {
        apiKey: `eaa7ec2c444e96fad842c126a29e929e`,
        shop: shop,
        host: host,
        forceRedirect: true
    }
}else{
    config = {
        apiKey: `eaa7ec2c444e96fad842c126a29e929e`,
        shop: shop,
        host: host,
        forceRedirect: true
    }
    apiEndpoint = "https://back.backinstocktech.com/node/admin_api";
}

module.exports = {
    apiEndpoint,
    shop,
    host,
    config
}
